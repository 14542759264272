<template>
	<prac-test :test=test>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";
export default {
  name: "network-layer",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "January 2019",
        title: "Network Layer",
        subtitle: "Learn more about the Network Layer of the TCP/IP model.",
        description: "Online flashcards on the data network layer.",
        bg: "/img/tests/networks-tree-200795.jpg",
        author: "Networks",
        references: "",
        items: [
          {
            front: "<h4>What is <b>fragmentation</b>?</h4>",
            back:
              "The router does fragmentation if the packet (datagram) has a bigger size than the maximal transmission unit of the frame. Thereby the received datagram is divided into fragments."
          },
          {
            front: "How does the receiver know whether a packet is fragmented?",
            back:
              'The fragments (except the last) will have the "more fragments" flag set. And the "fragment offset" field is nonzero (except the first will have 0).'
          },
          {
            front:
              "How does the router on the receiver side reassemble the data from the fragments?",
            back:
              'The router identifies the fragments with the same ID and reassembles the data through the fragment offset and "more fragments" flag.'
          },
          {
            front:
              "Does the fragmentation occur on layer 2 (Data Link) or L3 (Network)?",
            back:
              "Fragmentation occurs on L3. L3 must pass the data to L2 in a frame/packet size that matches the MTU of L2."
          },
          {
            front:
              "What is done when a host is about to send a datagram of 2'000 byte into a link that has a maximal transmission unit of 1'500 byte?",
            back:
              "The router will fragment the datagram by the size of the MTU of L2."
          },
          {
            front: "<h4>What is the <b>Count to Infinity Problem</b>?</h4>",
            back:
              "In Distance Vector Routing this problem is a routing loop that can occur when an interface goes down."
          },
          {
            front: "How does <b>Link State Routing</b> work?",
            back:
              "Routers exchange messages to learn the entire network topology from which then the routing table is calculated with a shortest path algorithm."
          },
          {
            front: "What is the motivation for a routing hierarchy?",
            back: "To reduce the size of routing tables."
          },
          {
            front: "What are the two levels of routing strategies?",
            back:
              "Interior Gateway Protocol (IGP) and Exterior Gateway Protocol (EGP). IGP are protocols within routing domains whereas EGP are protocols between routing domains."
          },
          {
            front:
              "How can a device communicate over the Internet without an IP address?",
            back: "This is not possible."
          },
          {
            front: "What is BGP?",
            back:
              "The Border Gateway Protocol that makes routing decisions based on paths, network policies, or rule-sets configured by a network administrator."
          },
          {
            front: "How can we classify routing algorithms?",
            back:
              "<ul><li><i>Distribution</i>: centralized, decentralized or isolated</li><li>And a routing algoritm is either <i>static</i> or <i>dynamic/adaptive</i>.</li></ul>"
          },
          {
            front:
              "IPv4: How do we know that an IP belongs to a class A network?",
            back:
              "Class A addresses start with the leading bit 0 => address space from 0.0.0.0 - 127.255.255.255. Because if leading bits are '01', the address belongs to Class B. Note that the binary number <span class=\"latex\">010000000=2^7=128</span>."
          },
          {
            front:
              'In IPv4, how can we connect more than <span class="latex">2^{32}</span> devices to the Internet?',
            back: "Through the use of local addresses and NAT."
          },
          {
            front: "What is a multicast address?",
            back:
              "A multicast is like a broadcast, but whereby only a group of hosts is targeted. In IPv4, multicast addresses start with 1110 (224.0.0.0 - 239.255.255.255)."
          },
          {
            front: "What is the subnet mask of a class A network?",
            back: "255.0.0.0"
          },
          {
            front: "IPv4: How many hosts does class A, B and C allow?",
            back:
              'A: <span class="latex">2^{24}</span>, B: <span class="latex">2^{16}</span>, C: <span class="latex">2^{8}</span>  (minus 2 as one address is the gateway address and the other the broadcast address of the network)'
          },
          {
            front:
              "<h4>What is <b>Classless Inter-Domain Routing</b> (CIDR)?</h4>",
            back:
              "CIDR is used for routing and allocates IP address more flexible."
          },
          {
            front: "What are the problems with Classful IP Addressing?",
            back:
              "Either wasting alot of hosts or having not enough as there are only 3 main classes."
          },
          {
            front: "What is the goal of CIDR?",
            back:
              "To slow the exhaustion of IPv4 addresses and to slow the growth of routing tables across the Internet."
          },
          {
            front: "How does CIDR slow the exhaustion of IPv4?",
            back:
              "Less unused IP addresses in a CLASS A/B/C network through variable-length subnet masking. Possible through extensive use of Network Address Translation (NAT)"
          },
          {
            front:
              "How can the following IP configuration: 192.142.5.1 and 255.255.255.248 be written with CIDR notation?",
            back: "192.142.5.1 / 29"
          },
          {
            front:
              "How would the default subnet mask of a class C network look if we want to divide the address range into two subnets?",
            back:
              "255.255.255.128 => therefore we get two new subnets with 126 - 2 hosts each."
          },
          {
            front: "<h4>Where are <b>local IP addresses</b> used?</h4>",
            back:
              "Inside a LAN, which is a private network. The local IP never appears inside packets outside of the LAN as local IP addresses cannot be routed through the public Internet."
          },
          {
            front: "Why were private address spaces originally defined?",
            back: "Because of the IPv4 address exhaustion."
          },
          {
            front:
              "What is the difference between the loop-back address and localhost?",
            back:
              "The same. The loopback address 127.0.0.1 is also referred to as localhost."
          },
          {
            front:
              'In IPv4, how can we connect more than <span class="latex">2^{32}</span> devices to the Internet?',
            back: "Through the use of local addresses and NAT."
          },
          {
            front: "Does IPv4 have any error control mechanisms?",
            back:
              "IPv4 can only detect errors through the checksum that is part of every packet's header. If the checksum is invalid, the packet is discarded and a message is sent over ICMP."
          },
          {
            front: "<h4>What is <b>IPv6</b>?</h4>",
            back:
              "IPv6 is the actual version of the Internet Protocol that was developed by the IETF. IPv6 is a draft standard since 1998 and became an Internet standard in 2017."
          },
          {
            front: "What were the design goals of IPv6?",
            back:
              "more addresses, IPSec (security by design), simpler headers and better support for mobile devices"
          },
          {
            front: "What are the reasons that IPv6 was not faster adopted?",
            back:
              "<ul><li>NAT</li><li>Older telcos have still enough IPv4 addesses</li><li>IPv6 implementations have/had bugs.</li></ul>"
          },
          {
            front: "What is IPv6 over IPv4 (6rd / 6in4)?",
            back:
              "The transistion mechanism to migrate from IPv4 to Ipv4 encapsulates IPv6 traffic over IPv4 links. Uses stateless address mappings between IPv4 and IPv6 addresses."
          },
          {
            front: "What is IPv4 over IPv6 (4rd)?",
            back:
              "Once the IPv4 to IPv6 transition is almost completed an IPv4 over IPv6 tunnel is used to connect deprecated IPv4 sites."
          },
          {
            front: "What is special about the  ::1/128 IPv6 address?",
            back: "Localhost"
          },
          {
            front: "What is the max number of IPv6 addresses?",
            back:
              'The length of IPv6 addreses is 128 bits. Therefore, this allows <span class="latex">2^{128}</span> addresses.'
          },
          {
            front: "<h4>What is a <b>routing table</b>?</h4>",
            back:
              "A routing table contains rules that are used to determine where data packets over a network are directed."
          },
          {
            front: "What is the default gateway?",
            back:
              "The node in a network that acts as a router. Nodes in the network send all packets to the default gateway when no other route matches the destination IP address of a packet."
          },
          {
            front:
              'What can you infer when the gateway of a destination address is <span class="latex">0.0.0.0</span> in the routing table?',
            back:
              "The gateway 0.0.0.0 means 'unspecified'. Therefore, we know that the host (destination) is locally connected and no hop is needed."
          },
          {
            front: "In a routing table: What does the flag 'UG' mean?",
            back: "U => the route is up and G => use gateway"
          }
        ]
      }
    };
  }
};
</script>
